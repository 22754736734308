import React from "react"
import { Box, Text, Flex, Image } from "@chakra-ui/react"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Feature2Image from "@/assets/images/userresearch/rewards/image-1.png"
import { headerHeight } from "@/utils/userresearch/constant"

const features = [
  { image: '' },
  { image: Feature2Image },
]

const Rewards = () => {
  const { t } = useTranslation()

  return (
    <Box
      as="section"
      id="Rewards"
      sx={{ scrollMarginTop: headerHeight }}
      width="full"
      position="relative"
      backgroundColor="#FAFAFA"
    >

      <Box mx="auto" maxW="5xl">
        <Flex
          px="6"
          py="20"
          columns={{ base: 1, md: 2 }}
          spacing="12"
          textAlign="center"
          pt="20px"
          direction={{ base: "column-reverse", md: "row" }}
        >
          {features.map(({ image }, index) => (
            <Flex
              direction="column"
              key={`feature-${index + 1}`}
              alignItems="start"
              px="4"
              height="120%"
              marginTop="35px"
              marginLeft={{ base: "0px", sm: "72px" }}
            >
            { image && <Image
                src={image}
                alt={ image && `${t(
                  `home-page.rewards.features.${index}.name`
                )} logo`}
                width={{ base: "320px", sm: "320px" }}
                height={{ base: "215px", sm: "215px" }}
              /> }

             { !image &&  <Text  fontFamily="Noto Sans" fontStyle="normal" lineHeight="38px" fontSize="24px" fontWeight="bold" color="rgba(15, 15, 15, 0.97);">
                {t(
                  `home-page.rewards.features.${index}.name`
                )}
              </Text> }

             {!image && <Text fontFamily="Noto Sans" fontStyle="normal" lineHeight="26px" fontSize="16px" fontWeight="400" color=" rgba(15, 15, 15, 0.8);" pt="20px" textAlign="initial">
                {t(
                  `home-page.rewards.features.${index}.description`
                )}
              </Text> }
            </Flex>
          ))}
        </Flex>
      </Box>
    </Box>
  )
}

export default Rewards
